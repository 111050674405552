<template>
  <div class="plan-list">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        抽题系统
      </div>
      <div class="right"></div>
    </div>
    <div class="unbind-block" v-if="noBound">
      <div class="unbind-container">
        <div class="unbind">
          <div class="unbind-item">
            <label class="unbind-label">课程：</label>
            <span>{{ courseVO.course.courseName }}</span>
          </div>
          <div class="unbind-item">
            <label class="unbind-label">请选择学科学段：</label>
          </div>
          <div class="unbind-item">
            <div class="unbind-select" @click="onPickPhase">{{ phaseValue }}</div>
          </div>
          <div class="unbind-item">
            <div class="unbind-select" @click="onPickSubject">{{ subjectValue }}</div>
          </div>
          <div class="unbind-button">
            <button class="btn" @click="bind">
              绑定
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasBound" class="bind-block">
      <div class="bind-container">
        <div class="bind">
          <div class="bind-item">
            <label class="bind-label">课程：</label>
            <span>{{ courseVO.course.courseName }}</span>
          </div>
          <div class="bind-item">
            <label class="bind-label">学科学段：</label>
            <span>{{ bindInfo.key }}</span>
          </div>
        </div>

        <div class="line"></div>
      </div>
    </div>
    <div v-if="hasBound" class="task-block">
      <div class="task-body">
        <div class="task-item" v-for="(item, index) in records" :key="item.id" @click="onViewTask(item.id)">
          <div class="task-item-title">第 {{ index + 1 }} 次抽题</div>
        </div>
        <div v-if="records.length == 0" class="empty" @click="onAddTask">
          暂无抽题记录<br />
          马上开始抽题
        </div>
      </div>
    </div>
    <div v-if="hasBound" class="add-task" @click="onAddTask">
      <img :src="addTaskIcon" />
    </div>
    <van-popup v-model="showPhase" position="bottom">
      <van-picker :columns="columns" @change="onChange" @cancel="showPhase = false" @confirm="onConfirm" />
    </van-popup>
    <van-popup v-model="showSubject" position="bottom">
      <van-picker :columns="subjectColumns" @change="onSubjectChange" />
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { courseApi } from "@/api/courseApi.js";
import { homeworkAssignApi } from "@/api/homeworkAssignApi.js";

export default {
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      addTaskIcon: require("@/assets/images/user-learn-plan/add-task.png"),
      columns: ["小学", "中学", "大学"],
      subjectColumns: ["语文", "数学", "自然", "英语"],
      phaseAndSubjectDatas: [],
      showPhase: false,
      showSubject: false,
      phaseValue: "",
      subjectValue: "",
      courseId: 0,
      courseVO: {
        course: {},
      },
      noBound: false,
      hasBound: false,
      bindInfo: { key: "" },
      records: [],
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId;

      courseApi
        .courseInfo(this.courseId)
        .then((ret) => {
          if (ret && ret.code == 0 && ret.data) {
            this.courseVO = ret.data;
            console.log("courseInfo...", this.courseVO);

            this.checkBindInfo(this.courseId);
          }
        })
        .catch(() => {
          Dialog.alert({
            message: "查询不到任何信息",
          });
        });
    } else {
      Dialog.alert({
        message: "缺少必要的参数值。",
      }).then(() => {
        // on close
      });
    }
  },
  methods: {
    onGoBack() {
      this.$router.push({
        path: "/courseDetail",
        query: {
          id: this.courseId,
        },
      });
    },
    checkBindInfo(courseId) {
      homeworkAssignApi.bindInfo(courseId).then((res) => {
        console.log("check bind info", res);
        if (res && res.code == 0 && res.data) {
          this.hasBound = true;
          this.bindInfo = res.data;

          //已经绑定过学科学段，获取抽题记录
          this.homeworkList(courseId);
        } else {
          this.noBound = true;
          // 通过接口获取学科学段数据
          this.phaseAndSubject();
        }
      });
    },
    phaseAndSubject() {
      homeworkAssignApi
        .phaseAndSubject()
        .then((res) => {
          if (res && res.code == 0 && res.data) {
            this.phaseAndSubjectDatas = res.data;
            this.phaseValue = this.phaseAndSubjectDatas[0].name;
            this.subjectValue = this.phaseAndSubjectDatas[0].subjects[0];

            var columns = [];
            this.phaseAndSubjectDatas.forEach((item) => {
              columns.push(item.name);
              if (item.name == this.phaseValue) {
                this.subjectColumns = item.subjects;
              }
            });
            this.columns = columns;
          }
        })
        .catch(() => {
          Dialog.alert({
            message: "获取学科学段信息失败",
          });
        });
    },
    homeworkList(courseId) {
      homeworkAssignApi.myList(courseId).then((res) => {
        if (res && res.code == 0 && res.datas) {
          this.records = res.datas;
        }
      });
    },
    onAddTask() {
      // console.log(this.courseVO.course);
      // this.$router.push({
      //   path: "/jzms-homework/detail",
      //   query: {
      //     id: 0,
      //     courseId: this.courseId,
      //     courseName: this.courseVO.course.courseName,
      //     key: this.bindInfo.key,
      //   },
      // });
      homeworkAssignApi
        .assignHomework(this.courseId)
        .then((res) => {
          if (res && res.code == 0 && res.data) {
            //this.detailVO = res.data;
            console.log("add task", res);
            this.homeworkList(this.courseId);
          } else {
            Dialog.alert({
              message: res.msg,
            });
          }
        })
        .catch(() => {
          Dialog.alert({
            message: "抽题失败。",
          });
        });
    },
    onViewTask(assignId) {
      this.$router.push({
        path: "/jzms-homework/detail",
        query: {
          id: assignId,
          courseId: this.courseId,
        },
      });
    },
    bind() {
      Dialog.confirm({
        message: "绑定学科学段后，不可更改\n确定好你的学科学段哦；)",
      })
        .then(() => {
          // on confirm
          var key = this.phaseValue + this.subjectValue;
          homeworkAssignApi.bind(this.courseId, key).then((res) => {
            console.log(res);
            if (res && res.code == 0 && res.data) {
              this.bindInfo.key = key;
              this.hasBound = true;
              this.noBound = false;
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      console.log(`当前值：${value}, 当前索引：${index}`);
      this.phaseValue = value;

      this.phaseAndSubjectDatas.forEach((item) => {
        if (item.name == value) {
          this.subjectColumns = item.subjects;
          this.subjectValue = this.subjectColumns[0];
        }
      });
    },
    onSubjectChange(picker, value, index) {
      this.subjectValue = value;
    },
    onPickPhase() {
      this.showPhase = true;
    },
    onPickSubject() {
      this.showSubject = true;
    },
    onConfirm(value) {
      // this.value = value;
      this.showPhase = false;
    },
  },
};
</script>

<style lang="less" scoped>
.plan-list {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100vh;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .unbind-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .unbind-container {
      border-radius: 8px 8px 8px 8px;
      background: #ffffff;
      margin: 14px 0px 0px 0px;
      padding-bottom: 14px;
      width: 344px;
      .unbind {
        margin: 12px 0px 0px 0px;
        font-size: 14px;
        color: #242424;
        font-weight: 500;
        .unbind-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .unbind-label {
            margin: 10px;
          }
          .unbind-select {
            margin: 10px;
            padding: 0px 0px 0px 10px;
            background: #efefef;
            border-radius: 8px;
            border-radius: 8px;
            width: 96px;
            height: 30px;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .unbind-button {
          display: flex;
          flex-direction: row-reverse;
          margin: 20px;
          .btn {
            width: 110px;
            height: 40px;
            background: #4d52d1;
            border: none;
            border-radius: 14px;
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            line-height: 30px;
          }
        }
      }
      .line {
        margin: 10px 10px;
        height: 1px;
        border-bottom: 1px solid rgb(239, 239, 239);
      }
    }
  }
  .bind-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bind-container {
      border-radius: 8px 8px 0px 0px;
      background: #ffffff;
      margin: 14px 0px 0px 0px;
      width: 344px;
      .bind {
        margin: 12px 0px 0px 0px;
        font-size: 14px;
        color: #242424;
        font-weight: 500;
        .bind-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .bind-label {
            margin: 10px;
          }
          .bind-select {
            margin: 10px;
            padding: 0px 0px 0px 10px;
            background: #efefef;
            border-radius: 8px;
            border-radius: 8px;
            width: 96px;
            height: 30px;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
      .line {
        margin: 10px 10px;
        height: 1px;
        border-bottom: 1px solid rgb(239, 239, 239);
      }
    }
  }
  .task-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
    //background: #ffffff;
    .task-header {
      height: 42px;
      width: 344px;
      display: flex;
      flex-direction: row;
      background: #ffffff;
      .task-label {
        height: 36px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        align-items: center;
        margin: 0px 0px 0px 20px;
        .taskActive {
          font-weight: 500;
          color: #242424;
          letter-spacing: 0;
        }
        .task-text {
          color: #9298a4;
          letter-spacing: 0;
        }
        .task-line {
          margin: 6px 0px 0px 0px;
          height: 4px;
          width: 24px;
          background: #4d52d1;
          border-radius: 16px;
        }
      }
    }
    .task-body {
      overflow: hidden;
      overflow-y: scroll;
      flex: 1;
      width: 344px;
      background: #ffffff;
      .task-item {
        height: 50px;
        width: 324px;
        margin: 10px;
        background: #fafafa;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        .task-item-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 285px;
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          padding: 6px 0px 4px 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px;
        font-size: 16px;
        color: #505051;
        letter-spacing: 0.36px;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-body {
      width: 344px;
      height: 20px;
      background: #ffffff;
    }
  }
  .add-task {
    position: fixed;
    z-index: 1;
    left: 320px;
    bottom: 48px;
    img {
      width: 44px;
      height: 44px;
    }
  }
}
</style>
